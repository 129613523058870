<template>
  <VtPage>
    <VtContainer>
      <div class="flex items-center justify-between gap-4 px-4 py-2.5">
        <NuxtLink
          :to="{ name: 'index' }"
          class="flex flex-col"
          :aria-label="t('brand.name')"
        >
          <img src="/brand-dark.svg" :alt="t('brand.name')" class="h-5 w-36" />
          <span
            class="text-xs leading-snug text-neutral-600 dark:text-neutral-400"
            translate="no"
          >
            {{ t('brand.appName') }}
          </span>
        </NuxtLink>
      </div>
    </VtContainer>
    <NuxtPage />
  </VtPage>
</template>

<script setup lang="ts">
import { NuxtLink, NuxtPage } from '#components'
import { useI18n } from '#i18n'

import { VtContainer, VtPage } from '@/components/vue-ui'
import { useColorMode } from '@/composables/useColorMode'

const { t } = useI18n()

useColorMode()
</script>
